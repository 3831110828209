.top-banner {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20vh 0px 0px 0px;
  z-index: 1;
}
.top-banner img {
  width: 100%;
  animation: fadeIn 5s;
  z-index:0;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.top-banner-image-text {
  position: absolute;
  left: 50vw;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  max-width: 40ch;
  text-align: center;
  transform: scale(0.94);
  animation: scale 3s forwards cubic-bezier(0.5, 1, 0.89, 1);
  font-size: 1.6vw;
  z-index: 0;
}
@keyframes scale {
  100% {
    transform: scale(1);
  }
}

span {
  display: inline-block;
  opacity: 0;
  filter: blur(10px);
  margin: 5px;
  z-index: 0;
}

span:nth-child(1) {
  animation: fade-in 0.8s 0.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
  color: rgb(82, 110, 235);
}

span:nth-child(2) {
  animation: fade-in 0.8s 0.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(3) {
  animation: fade-in 0.8s 0.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(4) {
  animation: fade-in 0.8s 0.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(5) {
  animation: fade-in 0.8s 1s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(6) {
  animation: fade-in 0.8s 1.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(7) {
  animation: fade-in 0.8s 1.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(8) {
  animation: fade-in 0.8s 1.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(9) {
  animation: fade-in 0.8s 1.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(10) {
  animation: fade-in 0.8s 2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(11) {
  animation: fade-in 0.8s 2.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(12) {
  animation: fade-in 0.8s 2.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(13) {
  animation: fade-in 0.8s 2.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(14) {
  animation: fade-in 0.8s 2.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(15) {
  animation: fade-in 0.8s 3s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(16) {
  animation: fade-in 0.8s 3.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(17) {
  animation: fade-in 0.8s 3.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

span:nth-child(18) {
  animation: fade-in 0.8s 3.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(19) {
  animation: fade-in 0.8s 3.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(20) {
  animation: fade-in 0.8s 4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(21) {
  animation: fade-in 0.8s 4.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(22) {
  animation: fade-in 0.8s 4.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(23) {
  animation: fade-in 0.8s 4.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(24) {
  animation: fade-in 0.8s 4.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(25) {
  animation: fade-in 0.8s 5s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(26) {
  animation: fade-in 0.8s 5.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(27) {
  animation: fade-in 0.8s 5.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(28) {
  animation: fade-in 0.8s 5.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(29) {
  animation: fade-in 0.8s 5.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(30) {
  animation: fade-in 0.8s 6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(31) {
  animation: fade-in 0.8s 6.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(32) {
  animation: fade-in 0.8s 6.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(33) {
  animation: fade-in 0.8s 6.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(34) {
  animation: fade-in 0.8s 6.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(35) {
  animation: fade-in 0.8s 7s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(36) {
  animation: fade-in 0.8s 7.2s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(37) {
  animation: fade-in 0.8s 7.4s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(38) {
  animation: fade-in 0.8s 7.6s forwards cubic-bezier(0.11, 0, 0.5, 0);
}
span:nth-child(39) {
  animation: fade-in 0.8s 7.8s forwards cubic-bezier(0.11, 0, 0.5, 0);
}

@keyframes fade-in {
  100% {
    opacity: 1;
    filter: blur(0);
  }
}

.banner-cards-container {
  display: flex;
  position: absolute;
  justify-content: space-around;
  top: 32vw;
  left: 10;
  width: 90vw;
}

.banner-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  flex: 10%;
  height: 10vh;
  padding: 20px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 10px;
}
.banner-card span {
  color: white;
}

.top-banner h1 {
  margin-bottom: 20vw;
}

@media screen and (max-width: 850px) {
  .banner-cards-container {
    top: 18vw;
  }

  .banner-card {
    height: 6vh;
  }

  .top-banner h1 {
    margin-bottom: 0vw;
  }
}

@media screen and (max-width: 600px) {
  .top-banner {
   height:auto;
  }
  .top-banner img{
    width: 150vw;
   }
  
  .top-banner-image-text {
    font-size: 1rem;
    top: 560px;
    left: 10px;
    width: 100vw;
    line-height: 0.5;
  }
  .banner-cards-container {
    top: 23vw;
  }

  .banner-card {
    width: 10%;
    flex: 5%;
    height: 5vh;
    font-size: 10px;
  }
}
