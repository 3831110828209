.top-header {
  display: flex;
  position: fixed;
  background-color: rgb(227, 229, 233);
  width: 90%;
  height: 20vh;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  color: rgb(75, 78, 228);
  font-size: 0.7vw;
  transition: height 1s, background-color 1s;
  padding-left: 100px;
  padding-right: 100px;
  z-index: 10;
}

.top-header-transparent {
  display: flex;
  position: fixed;
  color: black;
  background-color:white;
  box-shadow: lightgray 1px 1px 1px 1px;
  width: 90%;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
  transition: height 1s, background-color 1s;
  font-size: 0.7vw;
  padding-left: 100px;
  padding-right: 100px;
  z-index: 10;
}

.header-icon-container {
  display: flex;
}

.custom-header-right {
  display: flex;
  width: 30%;
  justify-content: flex-end;
  align-items: end;
  padding-left: 50px;
  padding-right: 50px;
}

.custom-header-right h1:hover {
  cursor: pointer;
  color: #223fc0;
}

.custom-header-left {
  text-transform: uppercase;
  background-image: linear-gradient(
    -225deg,
    #adc3f3 0%,
    #7aa2ec 29%,
    #4976d6 67%,
    #512fe7 100%
  );
  background-size: auto auto;
  background-clip: border-box;
  background-size: 200% auto;
  color: #fff;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: textclip 5s linear infinite;
  display: inline-block;
  font-size: 2vw;
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.custom-header-sesion {
  animation: 2s anim-lineUp ease-out;
  width: 40%;
  padding: 0;
}
@keyframes anim-lineUp {
  0% {
    opacity: 0;
    transform: translateY(80%);
  }
  20% {
    opacity: 0;
  }
  50% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

.custom-header-dropdown {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  right: 2vw;
  top: 20vh;
  background-color: white;
  width: 12vw;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  border: solid grey 0.5px;
  z-index: 10;
}

.custom-header-dropdown-transparent {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  right: 6vw;
  top: 10vh;
  background-color: white;
  width: 12vw;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s linear, opacity 0.5s linear;
  border: solid grey 0.5px;
  z-index: 10;
}

.custom-header-blog:hover > .custom-header-dropdown {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}
.custom-header-blog:hover > .custom-header-dropdown-transparent {
  visibility: visible;
  opacity: 1;
  z-index: 10;
}

.custom-header-dropdown ul {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-items: center;
  z-index: 10;
}

.custom-header-dropdown-transparent ul {
  list-style-type: none;
  z-index: 10;
}

.custom-header-dropdown-li {
  font-size: 1.2vw;
  padding: 10px;
  z-index: 10;
}

.custom-header-dropdown-li:hover {
  cursor: pointer;
  color: rgb(48, 60, 236);
  z-index: 10;
}

@media screen and (max-width: 850px) {
  .top-header {
    height: 10vh;
  }
}

@media screen and (max-width: 600px) {
  .top-header {
    width: 100%;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .top-header-transparent {
    width: 100%;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .top-header span,
  .top-header-transparent span {
    display: none;
  }
  .custom-header-left {
    font-size: 4vw;
  }

  .custom-header-right {
    font-size: 2vw;
  }

  .header-icon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
  }
  .custom-header-dropdown {
    width: 50vw;
    right: 1vw;
    top: 10vh;
    z-index: 10;
  }

  .custom-header-dropdown li {
    font-size: 5vw;
    z-index: 10;
  }

  .custom-header-dropdown-transparent {
    width: 50vw;
    right: 1vw;
    top: 10vh;
    z-index: 10;
  }

  .custom-header-dropdown-transparent li {
    font-size: 5vw;
    z-index: 10;
  }

  #topdoctors-co-widget-container-9 {
    width: 30vw;
    margin-top: 20px;
  }
}
