.blog-container {
  display: flex;
  flex-direction: column;
  width: 99vw;
  justify-content: start;
  align-items: center;
}
.blog-container-title {
  margin-top: 250px;
}

.blog-content {
  display: flex;
  margin-top: 50px;
  justify-content: space-around;
  align-items: start;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.8;
}

.blog-content img {
  width: 30vw;
  margin-top: 20px;
  margin-bottom: 20px;
}

.blog-content-left-section {
  display: flex;
  flex: 20%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  margin: 5px;
  align-items: flex-start;
  text-align: justify;
}

.blog-content-right-section {
  display: flex;
  flex: 20%;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 50px;
  margin: 5px;
  align-items: flex-end;
  text-align: justify;
}

.blog-content-end-paragraph {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.blog-content-end-paragraph div {
  width: 95%;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.8;
  padding: 50px;
  text-align: justify;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.blog-content-end-paragraph-img-container {
  width: 100vw;
  background-color: black;
}

.blog-content-end-paragraph img {
  width: 30%;
}

.blog-content-end-paragraph-content {
  margin-left: 5vw;
  margin-right: 5vw;
}

@media screen and (max-width: 600px) {
  .blog-content {
    flex-direction: column;
  }

  .blog-content img {
    width: 80vw;
  }

  .blog-content-end-paragraph img {
    width: 60%;
  }
}
