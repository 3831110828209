.App {
  display: flex;
  width: 99vw;
  flex-direction: column;
  background-color: white;
  overflow-x: hidden;
  text-align: center;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}

.whatsapp-sticky {
  position: fixed;
  width: 60px;
  top: 90vh;
  right: 2vw;
  cursor: pointer;
}

.blogs-footer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  background-color: rgb(33, 71, 148);
  color: rgb(255, 255, 255);
  padding: 20px;
  font-size: 2vw;
  flex-wrap: wrap;
}

.footer-item {
  cursor: pointer;
  flex: 20%;
  padding: 20px;
}
.footer-item:hover {
  color: white;
}

.card-wrapper{
  display:flex;
}
@media screen and (max-width: 600px) {
  .blogs-footer {
    font-size: 3vw;
  }
  .card-wrapper{
    flex-direction: column;
  }
}
