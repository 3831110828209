.opinion-card-container {
  display: flex;
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  padding: 50px;
  background-color: rgb(33, 71, 148);
}

.opinion-card {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border: solid 3px rgb(77, 25, 197);
  border-radius: 10px;
  background-color: white;
  width: 40vw;
}

.opinion-card-img {
  background-color: white;
  border-radius: 50%;
}
.opinion-card-img-container {
  display: flex;
  width: 7vw;
  height: 8vw;
  padding: 5px;
  border-radius: 50%;
  box-shadow: 2px 2px 2px 2px gray;
}

@media screen and (max-width: 600px) {
  .opinion-card {
    flex-direction: column;
    height: 28vh;
    align-items: center;
    justify-content: flex-start;
  }
  .opinion-card-img-container {
    display: flex;
    width: 10vw;
    height: 10vw;
    border: solid 2px black;
    border-radius: 50%;
  }

  .opinion-card-img-container {
    width: 25vw;
    height: 25vw;
    padding: 5px;
  }
}
