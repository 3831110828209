.subscription-form-container {
  display: flex;
  flex-direction: column;
  background-color: rgb(33, 71, 148);
  color: white;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  width: 50%;
  padding: 20px;
  font-size: 1rem;
}

.subscription-form-container input {
  width: 200px;
  height: 15px;
  margin: 5px 0px 5px 0px;
  border-radius: 10px;
  padding: 15px;
}

.subscription-form-container button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 40px;
  margin: 5px 0px 5px 0px;
  border-radius: 10px;
  padding: 15px;
  margin-top: 10px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
  background-color: rgb(11, 27, 121);
  color: white;
  border:none;
}

.subscription-form-container button:hover {
  cursor: pointer;
  background-color: rgb(7, 20, 93);
}
.subscription-form-title{
  width: 60%;
}

@media screen and (max-width: 600px) {
  .subscription-form-container {
    font-size: 0.8rem;
    width: 90vw;
  }

  .subscription-form-container input {
    width: 50%;
    height: 20px;
    padding: 5px;
  }
}
