.ask-section-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px;
  text-align: left;
}

.ask-section-card {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 1vw;
  margin: 2vw;
  border-top: solid 1px lightgrey;
}

.ask-section-card p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: lighter;
  line-height: 2;
}

@media screen and (max-width: 600px) {
  .ask-section-card {
    width: 80vw;
  }
}
