.profile-info-container {
  display: flex;
  flex-direction: column; 
  justify-items: flex-start;
  align-items: center;
  width: 50%;
  overflow-x: hidden;
  height: auto;
  background-color: rgb(33, 71, 148);
}

.profile-info-left-section {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%; 
  text-align: justify;
  background-color: rgb(33, 71, 148);
  color: white;
}
.profile-info-right-section {
  display: flex;
  width: 50%; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(33, 71, 148);
  margin: 60px;
}

.profile-info-right-section img {
  width: 50%;
  border-radius: 50%;
  transition: transform 1s;
}
.profile-info-right-section img:hover {
  transform: scale(1.2); 
}

.profile-info-images-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 30px;
}

.profile-info-left-section p,
.profile-info-right-section p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  font-weight: lighter;
  text-align: left;
}

@media screen and (max-width: 850px) {
  .profile-info-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width: 600px) {
  .profile-info-container {
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
  }

  .profile-info-images-container img {
    height: 5vh;
  }

  .profile-info-left-section {
    width: 70%;
    margin: 0px;
  }
  .profile-info-right-section {
    padding: 10px;
  }
}
